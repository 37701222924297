<template>
    <div id="newPushButtonAlertPage" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="newPushButtonAlert"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">

                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="alert_createNewPushButtonAlertSubTitle"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">

                            <div class="row">

                                <div class="col-md-12">

                                    <!--begin:: Portlet -->
                                    <div class="kt-portlet">

                                        <!--begin:: PortletHead -->
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <path d="M17,12 L18.5,12 C19.3284271,12 20,12.6715729 20,13.5 C20,14.3284271 19.3284271,15 18.5,15 L5.5,15 C4.67157288,15 4,14.3284271 4,13.5 C4,12.6715729 4.67157288,12 5.5,12 L7,12 L7.5582739,6.97553494 C7.80974924,4.71225688 9.72279394,3 12,3 C14.2772061,3 16.1902508,4.71225688 16.4417261,6.97553494 L17,12 Z" fill="#000000" />
                                                            <rect fill="#000000" opacity="0.3" x="10" y="16" width="4" height="4" rx="2" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("alert_createNewPushButtonAlert") }}
                                                </h3>
                                            </div>
                                        </div>
                                        <!--end:: PortletHead -->

                                        <!--begin:: Form-->
                                        <form class="kt-form" novalidate="novalidate">
                                            <!--begin:: PortletBody -->
                                            <div class="kt-portlet__body">

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group validated">
                                                            <label for="newAlert_nameInput">{{ $t("common_name") }} *</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-pencil"></i></span>
                                                                </div>
                                                                <input v-model="vName" @input="$v.vName.$touch()" type="text" class="form-control" id="newAlert_nameInput" :placeholder="$t('alert_pushButtonAlertNamePlaceholder')" />
                                                                <div v-if="!$v.vName.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <div v-else-if="!$v.vName.minLen" class="invalid-feedback">
                                                                    {{ $t("error_minLengthMsg", [$v.vName.$params.minLen.min]) }}
                                                                </div>
                                                            </div>
                                                            <span class="form-text text-muted"> {{ $t("alert_pushButtonAlertNameDetailInfo") }}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="kt-separator kt-separator--border-dashed " style="margin-bottom: 45px;"></div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group validated">
                                                            <label class="colorGeonotif">{{ $t("alert_pushButtonAlertSelectTriggerElement") }} <br /></label>
                                                            <div class="kt-radio-list" style=" margin-left: 10px; margin-top: 20px; ">
                                                                <div class="row">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="newAlert_noneInput" type="radio" v-model="vTrigger" name="triggerCondition" value="none" checked /> {{ $t("common_none") }}
                                                                        <span></span>
                                                                    </label>
                                                                </div>
                                                                <div class="row" style=" margin-top: 20px; ">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="newAlert_assetsRadio" type="radio" v-model="vTrigger" name="triggerCondition" value="asset" /> {{ $t("alert_pushButtonAlertTriggerAsset") }}
                                                                        <span></span>
                                                                    </label>
                                                                    <select id="newAlert_assetSelect2" multiple="multiple" class="form-control kt-select2" style="display:none;" disabled>
                                                                        <!--option></option-->
                                                                    </select>
                                                                    <div v-if="!isCorrectAssetSelected" class="invalid-feedback assetSelect2Error">
                                                                        {{ $t("error_fieldIsRequired") }}
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <div class="row">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="newAlert_categoriesRadio" type="radio" v-model="vTrigger" name="triggerCondition" value="assetCategory" /> {{ $t("alert_pushButtonAlertTriggerAssetCategory") }}
                                                                        <span></span>
                                                                    </label>
                                                                    <select id="newAlert_assetCategorySelect2" multiple="multiple" class="form-control kt-select2" disabled>
                                                                        <!--option></option-->
                                                                    </select>
                                                                    <div v-if="!isCorrectAssetCategorySelected" class="invalid-feedback assetCategorySelect2Error">
                                                                        {{ $t("error_fieldIsRequired") }}
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <div class="row">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="newAlert_subcategoriesRadio" type="radio" v-model="vTrigger" name="triggerCondition" value="assetSubcategory" /> {{ $t("alert_pushButtonAlertTriggerAssetSubcategory") }}
                                                                        <span></span>
                                                                    </label>
                                                                    <select id="newAlert_assetSubcategorySelect2" multiple="multiple" class="form-control kt-select2" disabled>
                                                                        <!--option></option-->
                                                                    </select>
                                                                    <div v-if="!isCorrectAssetSubcategorySelected" class="invalid-feedback assetSubcategorySelect2Error">
                                                                        {{ $t("error_fieldIsRequired") }}
                                                                    </div>
                                                                </div>
                                                                <br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="kt-separator kt-separator--border-dashed " style="margin-bottom: 45px;"></div>

                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group validated">
                                                            <label class="colorGeonotif">{{ $t("alert_pushButtonAlertTriggerCondition") }} </label>
                                                            <div class="row" style="margin-left: 0px;">
                                                                <label class="col-form-label" id="newAlert_pressLabel">{{ $t("alert_pushButtonAlertTriggerConditionPress") }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group validated">
                                                            <label>{{ $t("alert_pushButtonAlertEventType") }} *</label>
                                                            <select id="newAlert_eventTypeSelect2" class="form-control kt-select2">
                                                                <!--option></option-->
                                                            </select>
                                                            <div v-if="!$v.vEventType.required" class="invalid-feedback">
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                            <span class="form-text text-muted"> {{ $t("alert_pushButtonAlertEventTypeDetailInfo") }}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="kt-separator kt-separator--border-dashed " style="margin-bottom: 45px;"></div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group validated">
                                                            <label class="colorGeonotif">{{ $t("alert_pushButtonAlertRoleToNotified") }} </label>
                                                            <div class="kt-radio-list" style="margin-left: 10px; margin-top: 20px;">
                                                                <div class="row">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="newAlert_roleAllRadio" type="radio" v-model="vWhoToAlert" name="role" value="all" checked /> {{ $t("alert_pushButtonAlertAllRoleToNotified") }}
                                                                        <span></span>
                                                                    </label>
                                                                </div>
                                                                <div class="row" style=" margin-top: 20px; ">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="newAlert_roleAllSelectRadio" type="radio" v-model="vWhoToAlert" name="role" value="roles" /> {{ $t("alert_pushButtonAlertOnlyRoleToNotified") }}
                                                                        <span></span>
                                                                    </label>
                                                                    <select id="newAlert_roleSelect2" multiple="multiple" class="form-control kt-select2" disabled>
                                                                        <!--option></option-->
                                                                    </select>
                                                                    <div v-if="!isCorrectRoleSelected" class="invalid-feedback">
                                                                        {{ $t("error_fieldIsRequired") }}
                                                                    </div>
                                                                </div>
                                                                <div class="row" style=" margin-top: 20px; ">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="newAlert_onlyUsersSelectRadio" type="radio" v-model="vWhoToAlert" name="role" value="users"/> {{ $t("alert_pushButtonAlertOnlyUserToNotified") }}
                                                                        <span></span>
                                                                    </label>
                                                                    <select id="newAlert_userSelect2" multiple="multiple" class="form-control kt-select2" disabled>
                                                                        <!--option></option-->
                                                                    </select>
                                                                    <div v-if="!isCorrectUserSelected" class="invalid-feedback">
                                                                        {{ $t("error_fieldIsRequired") }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="kt-separator kt-separator--border-dashed " style="margin-bottom: 45px;"></div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group validated">
                                                            <label class="colorGeonotif">{{ $t("alert_pushButtonAlertMethodsFormTitle") }} *</label>
                                                            <div class="kt-checkbox-list" style=" margin-top: 20px; ">
                                                                <label v-for="notificationType in vNotificationTypesList" :key="notificationType.id" class="kt-checkbox kt-checkbox--brand kt-checkbox--bold">
                                                                    <input v-if="notificationType.name === 'WEBHOOK'" v-on:click="onCheckWebhook" :id="'notifTypeCheckbox_'+notificationType.name" type="checkbox" :value="notificationType.id" v-model="vNotificationTypes" />
                                                                    <input v-else-if="notificationType.name === 'IQM_SERVER' && isIQMServerDisabled()" :id="'notifTypeCheckbox_'+notificationType.name" type="checkbox" disabled/>
                                                                    <input v-else :id="'notifTypeCheckbox_'+notificationType.name" type="checkbox" :value="notificationType.id" v-model="vNotificationTypes" />{{ $t("geo_notif_"+notificationType.name) }}
                                                                    <a
                                                                        v-if="notificationType.name === 'MOBILE_PUSH_NOTIFICATION'"
                                                                        class="kt-link"
                                                                        id="linkWhatIsAndroidPushNotif"
                                                                        href="#"
                                                                        data-placement="right"
                                                                        data-toggle="kt-popover"
                                                                        data-html="true"
                                                                        title
                                                                        :data-content="$t('alert_AndroidPushExplain')"
                                                                        :data-original-title="$t('geo_notif_MOBILE_PUSH_NOTIFICATION')"
                                                                    >{{ $t("common_whatIsThis") }}</a>
                                                                    <a
                                                                        v-if="notificationType.name === 'WEBPUSH'"
                                                                        class="kt-link"
                                                                        id="linkWhatIsWebPushNotif"
                                                                        href="#"
                                                                        data-placement="right"
                                                                        data-toggle="kt-popover"
                                                                        data-html="true"
                                                                        title
                                                                        :data-content="$t('alert_WebPushExplain')"
                                                                        :data-original-title="$t('geo_notif_WEBPUSH')"
                                                                    >{{ $t("common_whatIsThis") }}</a>
                                                                    <a
                                                                        v-if="notificationType.name === 'IQM_SERVER' && isIQMServerDisabled()"
                                                                        class="kt-link"
                                                                        id="linkWhatIsIqmServer"
                                                                        href="#"
                                                                        data-placement="right"
                                                                        data-toggle="kt-popover"
                                                                        data-html="true"
                                                                        title
                                                                        :data-content="$t('geo_notif_IQMServerExplain')"
                                                                        :data-original-title="$t('geo_notif_IQM_SERVER')"
                                                                    >{{ $t("common_whyIsOptionDisabled") }}</a>
                                                                    <button v-else-if="notificationType.name === 'IQM_SERVER' && isIQMNotDeclaredAssets()" type="button" class="btn btn-sm btn-danger marginL5 customSmButtonPadding" @click="onIQMAssetsWarningModal" id="buttonIQMAssetsWarning">
                                                                        <i class="fa fa-exclamation-triangle"></i>{{ $t("common_attentionRequired") }}
                                                                    </button>
                                                                    <span></span>
                                                                </label>
                                                            </div>
                                                            <div v-if="!$v.vNotificationTypes.required" class="invalid-feedback">
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                            <div v-if="vWebhookIsChecked" class="webhookBloc">
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <div class="form-group validated">
                                                                            <label for="newWebhookUrl">{{ $t("geo_notif_webhookUrlFormTitle") }} *</label>
                                                                            <div class="input-group">
                                                                                <input v-model="vWebhookUrl" @input="$v.vWebhookUrl.$touch()" type="url" class="form-control" id="newWebhookUrl" :placeholder="$t('geo_notif_webhookUrlPlaceHolder')" />
                                                                                <div v-if="!$v.vWebhookUrl.required" class="invalid-feedback">
                                                                                    {{ $t("error_fieldIsRequired") }}
                                                                                </div>
                                                                                <div v-else-if="!$v.vWebhookUrl.url" class="invalid-feedback">
                                                                                    {{ $t("error_invalidUrlMsg") }}
                                                                                </div>
                                                                            </div>
                                                                            <span class="form-text text-muted"> {{ $t("geo_notif_webhookUrlDetailInfo") }}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-md-6" style=" margin-top: -10px; ">
                                                                        <label>{{ $t("geo_notif_webhookHeaderFormTitle") }}</label>
                                                                        <div class="form-group validated">
                                                                            <div class="alert alert-secondary">
                                                                                <div class="form-group row">
                                                                                    <label class="col-lg-5 col-form-label">{{ $t("geo_notif_webhookHeaderKeyFormTitle") }}</label>
                                                                                    <label class="col-lg-5 col-form-label">{{ $t("geo_notif_webhookHeaderValueFormTitle") }}</label>
                                                                                    <div id="webhookHeaders" class="col-lg-12">
                                                                                        <div id="header1" class="row kt-margin-b-20">
                                                                                            <div class="input-group col-lg-5">
                                                                                                <input type="text" name="key" class="form-control" />
                                                                                            </div>
                                                                                            <div class="input-group col-lg-5">
                                                                                                <input type="text" name="value" class="form-control" />
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <a href="javascript:;" class="btn btn-danger btn-icon">
                                                                                                    <i class="la la-close"></i>
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row kt-margin-t-20" style="margin-left:3px;">
                                                                                        <div class="col">
                                                                                            <div id="btAddNewHeader" v-on:click="onClickAddNewHeader" class="btn btn btn-brand marginTM30">
                                                                                                <span>
                                                                                                    <i class="la la-plus"></i>
                                                                                                    <span>{{ $t("geo_notif_webhookButtonAddHeader") }}</span>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="kt-separator kt-separator--border-dashed " style="margin-bottom: 45px;"></div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group validated">
                                                            <label class="colorGeonotif">{{ $t("alert_pushButtonAlertSeverityFormTitle") }} </label>
                                                            <div class="kt-radio-list" style="margin-left: 10px; margin-top: 20px;">
                                                                <div class="row">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="newAlert_severityHighRadio" type="radio" v-model="vSeverity" name="severity" value="HIGH" checked /> {{ $t("common_severityHigh") }}
                                                                        <span></span>
                                                                    </label>
                                                                </div>
                                                                <div class="row" style=" margin-top: 20px; ">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="newAlert_severityLowRadio" type="radio" v-model="vSeverity" name="severity" value="LOW" /> {{ $t("common_severityLow") }}
                                                                        <span></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="kt-separator kt-separator--border-dashed " style="margin-bottom: 45px;"></div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group validated">
                                                            <label class="colorGeonotif">{{ $t("alert_pushButtonAlertPeriodFormTitle") }} </label>
                                                            <div class="kt-radio-list" style="margin-left: 10px; margin-top: 20px;">
                                                                <div class="row">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="newAlert_dateAlwaysRadio" type="radio" v-model="vDateTime" name="datetime" value="always" v-on:click="disableDateSelect2" checked /> {{ $t("common_always") }}
                                                                        <span></span>
                                                                    </label>
                                                                </div>
                                                                <div class="row" style=" margin-top: 20px; ">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="newAlert_dateSelectionRadio" type="radio" v-model="vDateTime" class="col-md-4" name="datetime" value="period" v-on:click="enableDateSelect2" /> {{ $t("geo_notif_notificationFromStartTime") }}
                                                                        <span></span>
                                                                    </label>
                                                                    <div class="input-group date col-md-8" style=" margin-top: -9px; ">
                                                                        <div class="period input-group-prepend">
                                                                            <span> </span>
                                                                            <span class="input-group-text">
                                                                                <i class="la la-calendar"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" id="newAlert_startTimeDatePicker" :placeholder="$t('alert_pushButtonAlertStartdatetimePlaceholder')" class="form-control" disabled />
                                                                        <label class="col-form-label" style=" margin-left: 10px; margin-right: 10px; ">{{ $t("geo_notif_notificationToEndTime") }}</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text">
                                                                                <i class="la la-calendar"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" id="newAlert_endTimeDatePicker" :placeholder="$t('alert_pushButtonAlertEnddatetimePlaceholder')" class="form-control" disabled />
                                                                        <div v-if="!isCorrectEndDateTime" class="invalid-feedback" style=" margin-left: 4px; ">
                                                                            {{ $t("error_endDateIsNotCorrect") }}
                                                                        </div>
                                                                        <div v-if="!isDateSelected" class="invalid-feedback" style=" margin-left: 4px; ">
                                                                            {{ errorDateMessage }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="kt-separator kt-separator--border-dashed " style="margin-bottom: 45px;"></div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group validated">
                                                            <label class="colorGeonotif">{{ $t("alert_pushButtonAlertStatusTitle") }} </label>
                                                            <div class="row" style=" margin-top: 20px; margin-left: 0px; ">
                                                                <div>
                                                                    <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--success">
                                                                        <label>
                                                                            <input type="checkbox" checked="checked" id="newAlert_statusCheckbox" v-model="statusActive" @change="newNotificationStatusChange">
                                                                            <span></span>
                                                                        </label>
                                                                    </span>
                                                                </div>
                                                                <label style=" margin-top: -3px; margin-left: 5px; " class="col-form-label" id="newAlert_statusLabel">{{ statusMessage }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <!--end:: PortletBody -->

                                            <!--begin:: PortletFoot -->
                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions">
                                                    <div class="row">
                                                        <div class="col-lg-6 kt-align-right"></div>
                                                        <div class="col-lg-6 kt-align-right">
                                                            <label class="kt-checkbox kt-margin-r-20">
                                                                <input v-model="createAnother" type="checkbox" /> {{ $t("common_createAnother2") }}
                                                                <span></span>
                                                            </label>
                                                            <button id="newAlert_createButton" @click="onCreateButton" type="button" :disabled="$v.$invalid || !isCorrectAssetSelected || !isCorrectAssetCategorySelected || !isCorrectAssetSubcategorySelected || !isCorrectRoleSelected || !isCorrectUserSelected || !isCorrectEndDateTime || !isDateSelected" class="btn btn-brand kt-margin-r-5">
                                                                {{ $t("common_add") }}
                                                            </button>
                                                            <button id="newAlert_cancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--begin:: PortletFoot -->

                                        </form>
                                        <!--end:: Form-->
                                    </div>
                                    <!--end:: Portlet -->

                                </div>
                            </div>

                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: Modal -->
                    <app-iqmassetwarningmodal :textWarning="iqmAssetWarning"></app-iqmassetwarningmodal>
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end:: Modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, minLength, url, requiredIf, integer, minValue } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import SiteTitle from "../site/widgets/sitetitle.vue";
import IQMAssetWarningModal from "../modals/iqmassetwarningmodal.vue";
import commonVueHelper from "../../helpers/commonVueHelper";
import i18n from "../../i18n";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            triggerAssets: [],
            triggerCategories: [],
            vName: null,
            vTrigger: "none",
            vCondition: "PRESS",
            vSeverity: "HIGH",
            vWhoToAlert: "all",
            vDateTime: "always",
            vAssets: [],
            vAssetCategories: [],
            vAssetSubcategories: [],
            vRoles: [],
            vUsers: [],
            vEventType: null,
            vStartTime: null,
            vEndTime: null,
            vNotificationTypes: [],
            vNotificationTypesList: null,
            vWebhookIsChecked: false,
            vWebhookUrl: null,
            statusMessage: i18n.t("alert_statusActive"),
            statusActive: true,
            createAnother: false,
            isCorrectEndDateTime: true,
            isCorrectAssetSelected: true,
            isCorrectAssetCategorySelected: true,
            isCorrectAssetSubcategorySelected: true,
            isCorrectRoleSelected: true,
            isCorrectUserSelected: true,
            isDateSelected: true,
            errorDateMessage: i18n.t('error_dateRequired'),
            iqmNotificationTypeId: null,
            iqmNotDeclaredAssetNames: null,
            iqmAssetWarning: ""
        };
    },
    created: function() {
        console.log("Component(newPushButtonAlert)::created() - called");
        this.createAnother = this.createAnotherPushButtonAlert;
        this.setCreateAnotherPushButtonAlert(false);

        // Get all push button alerts to filter only assets and categories not used in an push button alert
        this.getSitePushButtonAlerts(this.siteId);

        this.getPushButtonNotificationTypes();
        this.getSitePushButtonEventTypes(this.siteId);
        this.getSiteRoles(this.siteId);
        this.getUsersOfSite(this.siteId);
    },
    mounted: function() {
        console.log("Component(newPushButtonAlert)::mounted() - Init metronic layout");
        KTLayout.init();
        this.initStartDateTimePicker();
        this.initEndDateTimePicker();
        $('[data-toggle="kt-popover"]').popover({ trigger: "hover" });
    },
    destroyed: function() {
        console.log("Component(newPushButtonAlert)::destroyed() - called");
        this.resetAssetsState();
        this.resetCategoriesState();
        this.resetSiteRolesState();
        this.resetSiteUsersState();
        this.resetPushButtonAlertState();
        this.destroyDatePicker();
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        vName: {
            required,
            minLen: minLength(2)
        },
        vWebhookUrl: {
            url,
            required: requiredIf(function() {
                return this.vWebhookIsChecked;
            })
        },
        vEventType: {
            required
        },
        vCondition: {
            required
        },
        vNotificationTypes: {
            required
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --

        // Array of assets associated to a push button alert
        pushButtonAlertAssets: function(assets) {
            console.log("Component(newPushButtonAlert)::watch(pushButtonAlertAssets) called", assets);
            // Get all site assets
            this.getSiteAssets(this.siteId);
        },

        // Array of asset categories associated to a push button alert
        pushButtonAlertCategories: function(categories) {
            console.log("Component(newPushButtonAlert)::watch(pushButtonAlertCategories) called", categories);
            // Get all site asset categories and subcategories
            this.getSiteAssetCategories(this.siteId);
        },

        // Array of asset subcategories associated to a push button alert
        pushButtonAlertSubcategories: function(subcategories) {
            console.log("Component(editPushButtonAlert)::watch(pushButtonAlertSubcategories) called", subcategories);
        },

        // Array of assets associated to a tag
        siteAssetsWithTag: function(assetsWithTag) {
            console.log("Component(newPushButtonAlert)::watch(siteAssetsWithTag) - called", assetsWithTag);
            if (assetsWithTag) {
                if (this.pushButtonAlertAssets) {
                    // Remove from assets list the assets which are already associated to a push button alert
                    this.triggerAssets = assetsWithTag.filter( assetWithTag =>
                        !this.pushButtonAlertAssets.find( asset => assetWithTag.id === asset.id )
                    );
                } else {
                    this.triggerAssets = assetsWithTag;
                }
                this.initAssetSelect2();
            }
        },

        user: function(user) {
            if (user) {
                console.log("Component(newPushButtonAlert)::watch(user) called with : ", user);
                // Update language
                this.initAssetSelect2();
                this.initAssetCategorySelect2();
                this.initAssetSubcategorySelect2();
                this.initUsersSelect2();
                this.initRoleSelect2();
                this.initEventTypeSelect2();
            }
        },

        vTrigger: function(value) {
            console.log("Component(editPushButtonAlert)::watch(vTrigger) - called", value);
            switch (value) {
                case "none":
                    this.enableTriggerNoneSelect2();
                    break;
                case "asset":
                    this.enableTriggerAssetSelect2();
                    break;
                case "assetCategory":
                    this.enableTriggerAssetCategorySelect2();
                    break;
                case "assetSubcategory":
                    this.enableTriggerAssetSubcategorySelect2();
                    break;
                default:
                    // Defense
                    this.enableTriggerNoneSelect2();
            }
        },

        siteAssetCategories: function(categories) {
            console.log("Component(newPushButtonAlert)::watch(siteAssetCategories) - called", categories);
            if (categories) {
                if (this.pushButtonAlertCategories) {
                    // Remove from asset categories list the categories which are already associated a push button alert
                    this.triggerCategories = categories.filter( category =>
                        !this.pushButtonAlertCategories.find( pushButtonAlertCategory => category.id === pushButtonAlertCategory.id )
                    );
                } else {
                    this.triggerCategories = categories;
                }
                this.initAssetCategorySelect2();
                this.initAssetSubcategorySelect2();
            }
        },

        siteRoles: function(roles) {
            console.log("Component(newPushButtonAlert)::watch(siteRoles) - called", roles);
            if (roles) {
                this.initRoleSelect2();
            }
        },

        usersOfSite: function(siteUser) {
            console.log("Component(newPushButtonAlert)::watch(usersOfSite) - called", siteUser);
            if (siteUser) {
                this.initUsersSelect2();
            }
        },

        pushButtonNotificationTypes: function(notificationTypes) {
            console.log("Component(newPushButtonAlert)::watch(pushButtonNotificationTypes) - called", notificationTypes);
            if (notificationTypes) {
                this.vNotificationTypesList = _.orderBy(
                    notificationTypes,
                    [notificationType => notificationType.name],
                    ["asc"]
                );
            }
            // Set the webhook in the last
            const findWebHook = _.find(this.vNotificationTypesList, {name: "WEBHOOK"});
            this.vNotificationTypesList = _.reject(this.vNotificationTypesList, {name: "WEBHOOK"});
            this.vNotificationTypesList.push(findWebHook);

            const iqmNotificationType = _.find(this.vNotificationTypesList, {name: "IQM_SERVER"});
            this.iqmNotificationTypeId = (iqmNotificationType ? iqmNotificationType.id : null);

            setTimeout(function(){
                $('[data-toggle="kt-popover"]').popover({ trigger: "hover" });
            }, 300);
        },

        sitePushButtonEventTypes: function(eventTypes) {
            console.log("Component(newPushButtonAlert)::watch(sitePushButtonEventTypes) - called", eventTypes);
            if (eventTypes) {
                this.initEventTypeSelect2();
            }

        },

        vCondition: function(value) {
        },

        // Enable or disable select 2
        vWhoToAlert: function(value) {
            switch (value) {
                case "all":
                    // Disable all select2 in who will be notified
                    this.disableRoleSelect2();
                    this.disableUserSelect2();
                    break;
                case "roles":
                    // Enable role select2 and disable user select 2
                    this.enableRoleSelect2();
                    this.disableUserSelect2();
                    break
                case "users":
                    // Enable user select 2 and disable role select 2
                    this.enableUserSelect2();
                    this.disableRoleSelect2();
                    break;
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([
            "currentSite",
            "siteAssetsWithTag",
            "siteAssetCategories",
            "siteRoles",
            "usersOfSite",
            "pushButtonAlertAssets",
            "pushButtonAlertCategories",
            "pushButtonAlertSubcategories",
            "pushButtonNotificationTypes",
            "sitePushButtonEventTypes",
            "createAnotherPushButtonAlert",
            "user"
        ])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "getSitePushButtonAlerts",
            "getSiteAssets",
            "getSiteAssetCategories",
            "getUsersOfSite",
            "getSiteRoles",
            "getPushButtonNotificationTypes",
            "getSitePushButtonEventTypes",
            "createPushButtonAlert",
            "setCreateAnotherPushButtonAlert",
            "resetAssetsState",
            "resetCategoriesState",
            "resetSiteRolesState",
            "resetSiteUsersState",
            "resetPushButtonAlertState"
        ]),

        initAssetSelect2() {
            var self = this;
            // Init Select2 data of asset
            let dataAssetSelect2 = [];
            for (let i = 0; i < this.triggerAssets.length; i++) {
                let assetName = this.triggerAssets[i].name;
                dataAssetSelect2.push({
                    id: this.triggerAssets[i].id,
                    text: assetName,
                    assetName: assetName
                });
            }
            // Ascending sort order Select2 data by text
            dataAssetSelect2 = _.orderBy(
                dataAssetSelect2,
                [asset => asset.text.toLowerCase()],
                ["asc"]
            );
            // Init assets select2
            commonVueHelper.destroySelect2($("#newAlert_assetSelect2"));

            $("#newAlert_assetSelect2")
                .select2({
                    placeholder: i18n.t("alert_pushButtonAlertSelectAsset"),
                    width: "30%",
                    data: dataAssetSelect2,
                    minimumResultsForSearch: -1,
                    containerCssClass: "geonotifSelect2"
                })
                .val(this.vAssets)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    // Store category index to retrieve associated subcategory
                    self.vAssets = $(this).val();
                    if (self.vAssets.length == 0) {
                        self.isCorrectAssetSelected = false;
                    } else {
                        self.isCorrectAssetSelected = true;
                    }
                    // Set list of selected asset names not declared in IQM
                    self.setIQMNotDeclaredAssetNames();
                });
        },

        initAssetCategorySelect2() {
            var self = this;
            // Init Select2 data of categories
            let dataCategorySelect2 = [];
            for (let i = 0; i < this.triggerCategories.length; i++) {
                let categoryName = this.triggerCategories[i].name;
                let isDefault = this.triggerCategories[i].isDefault;
                let image = this.triggerCategories[i].iconImg;
                let categoryNameTranslated;
                if (this.triggerCategories[i].isDefault) {
                    categoryNameTranslated = i18n.t(categoryName);
                } else {
                    categoryNameTranslated = categoryName;
                }
                dataCategorySelect2.push({
                    id: this.triggerCategories[i].id,
                    text: categoryNameTranslated,
                    categoryName: categoryName,
                    isDefault: isDefault,
                    image: image
                });
            }
            // Ascending sort order Select2 data by text
            dataCategorySelect2 = _.orderBy(
                dataCategorySelect2,
                [category => category.text.toLowerCase()],
                ["asc"]
            );
            // Init categories Select2
            commonVueHelper.destroySelect2(
                $("#newAlert_assetCategorySelect2")
            );

            function formatCategory(categoryItem) {
                if (!categoryItem.id) return categoryItem.text;
                if (categoryItem.isDefault) {
                    return (
                        "<img class='flag' style='width:30px;margin-right:5px' src='/assets/categories/" +
                        categoryItem.categoryName +
                        "/Icon.png'/>" +
                        categoryItem.text
                    );
                } else {
                    return (
                        "<img class='iconPreviewCustomCat' style='background-image:url(" +
                        categoryItem.image +
                        ");margin-right:5px' />" +
                        categoryItem.text
                    );
                }
            }

            $("#newAlert_assetCategorySelect2")
                .select2({
                    placeholder: i18n.t("alert_pushButtonAlertSelectAssetCategory"),
                    width: "30%",
                    data: dataCategorySelect2,
                    minimumResultsForSearch: -1,
                    escapeMarkup: function(m) {
                        return m;
                    },
                    templateResult: formatCategory,
                    templateSelection: formatCategory,
                    containerCssClass: "geonotifSelect2"
                })
                .val(this.vAssetCategories)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    self.vAssetCategories = $(this).val();
                    if (self.vAssetCategories.length === 0) {
                        self.isCorrectAssetCategorySelected = false;
                    } else {
                        self.isCorrectAssetCategorySelected = true;
                    }
                });
        },

        initAssetSubcategorySelect2() {
            var self = this;
            // assetSubcategoriesByCategoryName list as {"category1": [objectSubCat1, objectSubCat2, ..], "category2": [objectSubCat10, ...], ...}
            const assetSubcategoriesByCategoryName = self.siteAssetCategories.reduce(
                (accumulator, currentCategory) => ({...accumulator, [currentCategory.name]: currentCategory.subcategories}),
                {}
            );
            let dataGroup = {};
            for (let category of self.siteAssetCategories) {
                // Keep only sub categories which are not associated to a push button alert
                assetSubcategoriesByCategoryName[category.name] = assetSubcategoriesByCategoryName[category.name].filter( subcategory =>
                    !this.pushButtonAlertSubcategories.find( pushButtonAlertSubcategory => subcategory.id === pushButtonAlertSubcategory.id )
                );
                if (assetSubcategoriesByCategoryName[category.name].length > 0) {
                    let childSubcategories = assetSubcategoriesByCategoryName[category.name].map(subcategory => {
                        let childElement = {
                            id: subcategory.id,
                            text: subcategory.isDefault && subcategory.name ? i18n.t(subcategory.name) : subcategory.name,
                            name: subcategory.name
                        }
                        return childElement;
                    });
                    const categoryTranslatedName = category.isDefault && category.name ? i18n.t(category.name) : category.name;
                    if (!dataGroup[category.name]) {
                        dataGroup[category.name] = {text: categoryTranslatedName};
                    } else {
                        dataGroup[category.name].text = categoryTranslatedName;
                    }
                    if (!dataGroup[category.name].children) {
                        dataGroup[category.name].children = [];
                    }
                    childSubcategories = _.orderBy(
                        childSubcategories,
                        [subcategory => subcategory.text.toLowerCase()],
                        ["asc"]
                    );
                    dataGroup[category.name].children = dataGroup[category.name].children.concat(childSubcategories);
                }
            }

            // Ascending sort order Select2 data by text
            dataGroup = _.orderBy(
                dataGroup,
                [category => category.text.toLowerCase()],
                ["asc"]
            );
            // Init categories Select2
            const categorySelector = $("#newAlert_assetSubcategorySelect2");
            commonVueHelper.destroySelect2(categorySelector);
            categorySelector
                .select2({
                    placeholder: i18n.t("alert_pushButtonAlertSelectAssetSubcategory"),
                    width: "30%",
                    data: dataGroup,
                    minimumResultsForSearch: -1,
                    escapeMarkup: function(m) {
                        return m;
                    },
                    containerCssClass: "geonotifSelect2"
                })
                .val(this.vAssetSubcategories)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    self.vAssetSubcategories = $(this).val();
                    if (self.vAssetSubcategories.length === 0) {
                        self.isCorrectAssetSubcategorySelected = false;
                    } else {
                        self.isCorrectAssetSubcategorySelected = true;
                    }
                });
        },

        initEventTypeSelect2() {
            var self = this;
            // Init Select2 data of categories
            let dataEventTypeSelect2 = [];
            for (let eventTypeObj of this.sitePushButtonEventTypes) {
                if (eventTypeObj) {
                    dataEventTypeSelect2.push({
                        id: eventTypeObj.id,
                        text:(eventTypeObj.isDefault ? i18n.t("alert_pushEventType_" + eventTypeObj.name) : eventTypeObj.name)
                    });
                }
            }
            // Ascending sort order Select2 data by text
            dataEventTypeSelect2 = _.orderBy(
                dataEventTypeSelect2,
                [eventType => eventType.text.toLowerCase()],
                ["asc"]
            );
            const eventTypeSelector = $("#newAlert_eventTypeSelect2");
            commonVueHelper.destroySelect2(eventTypeSelector);
            eventTypeSelector
                .select2({
                    placeholder: i18n.t("alert_pushButtonEventTypeSelect"),
                    width: "100%",
                    data: dataEventTypeSelect2
                })
                .val(this.vEventType)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    // Store category index to retrieve associated subcategory
                    self.vEventType = $(this).val();
                });
        },

        initRoleSelect2() {
            var self = this;
            // Init Select2 data of role
            let dataRoleSelect2 = [];
            for (let i = 0; i < this.siteRoles.length; i++) {
                var roleName =
                    this.siteRoles[i].isDefault === true
                        ? i18n.t("ROLE_" + this.siteRoles[i].name)
                        : this.siteRoles[i].name;
                dataRoleSelect2.push({
                    id: this.siteRoles[i].id,
                    text: roleName,
                    roleName: roleName
                });
            }
            // Ascending sort order Select2 data by text
            dataRoleSelect2 = _.orderBy(
                dataRoleSelect2,
                [role => role.text.toLowerCase()],
                ["asc"]
            );
            // Init roles select2
            commonVueHelper.destroySelect2($("#newAlert_roleSelect2"));

            $("#newAlert_roleSelect2")
                .select2({
                    placeholder: i18n.t("alert_pushButtonAlertSelectRole"),
                    width: "40%",
                    data: dataRoleSelect2,
                    minimumResultsForSearch: -1,
                    containerCssClass: "geonotifSelect2"
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    self.vRoles = $(this).val();
                    if (self.vRoles.length == 0) {
                        self.isCorrectRoleSelected = false;
                    } else {
                        self.isCorrectRoleSelected = true;
                    }
                });
        },

        initUsersSelect2() {
            var self = this;
            // Init Select2 data of users
            let dataUsersSelect2 = [];
            for (let user of this.usersOfSite) {
                dataUsersSelect2.push({
                    id: user.user.id,
                    text: user.user.firstName + " " + user.user.lastName
                });
            }
            // Ascending sort order Select2 data by text
            dataUsersSelect2 = _.orderBy(dataUsersSelect2, ["text"], ["asc"]);
            // Init users select2
            commonVueHelper.destroySelect2($("#newAlert_userSelect2"));

            $("#newAlert_userSelect2")
                .select2({
                    placeholder: i18n.t("alert_pushButtonAlertSelectUser"),
                    width: "40%",
                    data: dataUsersSelect2,
                    minimumResultsForSearch: -1,
                    containerCssClass: "geonotifSelect2"
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    self.vUsers = $(this).val();
                    if (self.vUsers.length == 0) {
                        self.isCorrectUserSelected = false;
                    } else {
                        self.isCorrectUserSelected = true;
                    }
                });
        },

        initStartDateTimePicker() {
            const selector = $("#newAlert_startTimeDatePicker");
            const startDate = commonVueHelper.getToDayPlusXmin(5);
            commonVueHelper.initDateTimePicker(
                selector,
                "top-right",
                this.vStartTime,
                startDate,
                null
            );
            selector.on("changeDate", event => {
                this.vStartTime = new Date(event.date.valueOf());
                if (this.vEndTime) {
                    this.isDateSelected = true;
                } else {
                    this.isDateSelected = false;
                    this.errorDateMessage = i18n.t('error_endDateRequired');
                }
                if (this.vEndTime && this.vStartTime >= this.vEndTime) {
                    this.isCorrectEndDateTime = false;
                } else {
                    this.isCorrectEndDateTime = true;
                }
            });
            selector.on("focusout", event => {
                if (!selector.val()) {
                    this.vStartTime = null;
                    this.isCorrectEndDateTime = true;
                    this.isDateSelected = false;
                    this.errorDateMessage = i18n.t('error_startDateRequired');
                    if (!this.vEndTime) {
                        this.errorDateMessage = i18n.t('error_dateRequired');
                    }
                }
            });
        },

        initEndDateTimePicker() {
            const selector = $("#newAlert_endTimeDatePicker");
            const startDate = commonVueHelper.getToDayPlusXmin(5);
            commonVueHelper.initDateTimePicker(
                selector,
                "top-right",
                this.vEndTime,
                startDate,
                null
            );
            selector.on("changeDate", event => {
                this.vEndTime = new Date(event.date.valueOf());
                if (this.vStartTime) {
                    this.isDateSelected = true;
                } else {
                    this.isDateSelected = false;
                    this.errorDateMessage = i18n.t('error_startDateRequired');
                }

                if (this.vStartTime && this.vEndTime <= this.vStartTime) {
                    this.isCorrectEndDateTime = false;
                } else {
                    this.isCorrectEndDateTime = true;
                }
            });
            selector.on("focusout", event => {
                if (!selector.val()) {
                    this.vEndTime = null;
                    this.errorDateMessage = i18n.t('error_endDateRequired');
                    this.isCorrectEndDateTime = true;
                    this.isDateSelected = false;
                    if (!this.vStartTime) {
                        this.errorDateMessage = i18n.t('error_dateRequired');
                    }
                }
            });
        },

        destroyDatePicker() {
            $("#newAlert_startTimeDatePicker").datepicker('destroy');
            $("#newAlert_endTimeDatePicker").datepicker('destroy');
        },

        enableTriggerNoneSelect2() {
            $("#newAlert_assetSelect2").attr("disabled", "true");
            $("#newAlert_assetCategorySelect2").attr("disabled", "true");
            $("#newAlert_assetSubcategorySelect2").attr("disabled", "true");
            this.isCorrectAssetSelected = true;
            this.isCorrectAssetCategorySelected = true;
            this.isCorrectAssetSubcategorySelected = true;
        },

        enableTriggerAssetCategorySelect2() {
            $("#newAlert_assetSelect2").attr("disabled", "true");
            $("#newAlert_assetCategorySelect2").removeAttr("disabled");
            $("#newAlert_assetSubcategorySelect2").attr("disabled", "true");
            this.isCorrectAssetSelected = true;
            if (this.vAssetCategories.length === 0) {
                this.isCorrectAssetCategorySelected = false;
            } else {
                this.isCorrectAssetCategorySelected = true;
            }
            this.isCorrectAssetSubcategorySelected = true;
        },

        enableTriggerAssetSubcategorySelect2() {
            $("#newAlert_assetSelect2").attr("disabled", "true");
            $("#newAlert_assetCategorySelect2").attr("disabled", "true");
            $("#newAlert_assetSubcategorySelect2").removeAttr("disabled");
            this.isCorrectAssetSelected = true;
            if (this.vAssetSubcategories.length === 0) {
                this.isCorrectAssetSubcategorySelected = false;
            } else {
                this.isCorrectAssetSubcategorySelected = true;
            }
            this.isCorrectAssetCategorySelected = true;
        },

        enableTriggerAssetSelect2() {
            $("#newAlert_assetCategorySelect2").attr("disabled", "true");
            $("#newAlert_assetSubcategorySelect2").attr("disabled", "true");
            $("#newAlert_assetSelect2").removeAttr("disabled");
            this.isCorrectAssetCategorySelected = true;
            this.isCorrectAssetCategorySelected = true;
            if (this.vAssets.length == 0) {
                this.isCorrectAssetSelected = false;
            } else {
                this.isCorrectAssetSelected = true;
            }
        },

        disableRoleSelect2() {
            $("#newAlert_roleSelect2").attr("disabled", "true");
            this.isCorrectRoleSelected = true;
        },

        disableUserSelect2() {
            $("#newAlert_userSelect2").attr("disabled", "true");
            this.isCorrectUserSelected = true;
        },

        enableRoleSelect2() {
            $("#newAlert_roleSelect2").removeAttr("disabled");
            if (this.vRoles.length == 0) {
                this.isCorrectRoleSelected = false;
            } else {
                this.isCorrectRoleSelected = true;
            }
        },
        enableUserSelect2() {
            $("#newAlert_userSelect2").removeAttr("disabled");
            if (this.vUsers.length == 0) {
                this.isCorrectUserSelected = false;
            } else {
                this.isCorrectUserSelected = true;
            }
        },

        disableDateSelect2() {
            $("#newAlert_startTimeDatePicker").attr("disabled", "true");
            $("#newAlert_startTimeDatePicker").val(null);
            $("#newAlert_endTimeDatePicker").attr("disabled", "true");
            $("#newAlert_endTimeDatePicker").val(null);
            this.vStartTime = this.vEndTime = null;
            this.isCorrectEndDateTime = true;
            this.isDateSelected = true;
        },

        enableDateSelect2() {
            $("#newAlert_startTimeDatePicker").removeAttr("disabled");
            $("#newAlert_endTimeDatePicker").removeAttr("disabled");
            if (this.vStartTime && this.vEndTime) {
                this.isDateSelected = true;
            } else{
                this.isDateSelected = false;
                this.errorDateMessage = i18n.t('error_dateRequired');
                if(this.vStartTime && !this.vEndTime) {
                    this.errorDateMessage = i18n.t('error_endDateRequired');
                } else if(!this.vStartTime && this.vEndTime) {
                    this.errorDateMessage = i18n.t('error_startDateRequired');
                }
            }
        },

        newNotificationStatusChange() {
            if (this.statusActive) {
                this.statusMessage = i18n.t("alert_statusActive");
            } else {
                this.statusMessage = i18n.t("alert_statusInactive");
            }
        },

        emptyUnselectedTrigger() {
            console.log("Component(editPushButtonAlert)::resetTriggerValue() - called for selected trigger " + this.vTrigger);
            switch (this.vTrigger) {
                case "none":
                    this.vAssets = [];
                    this.vAssetCategories = [];
                    this.vAssetSubcategories = [];
                    break;
                case "asset":
                    this.vAssetCategories = [];
                    this.vAssetSubcategories = [];
                    break;
                case "assetCategory":
                    this.vAssets = [];
                    this.vAssetSubcategories = [];
                    break;
                case "assetSubcategory":
                    this.vAssets = [];
                    this.vAssetCategories = [];
                    break;
                default:
                    // Defense
                    this.vAssets = [];
                    this.vAssetCategories = [];
                    this.vAssetSubcategories = [];
            }
        },

        // Function is executed when user check the webhook checkbox
        onCheckWebhook(event) {
            console.log("Component(newPushButtonAlert)::onCheckWebHook() - called");
            if(event.target.checked) {
                this.vWebhookIsChecked = true;
            } else {
                this.vWebhookIsChecked = false;
            }
        },

        // Function which permits to add new header
        onClickAddNewHeader() {
            console.log("Component(newPushButtonAlert)::onClickAddNewHeader() - called");
            var headers = $("#webhookHeaders");
            var newId = "header" + (headers.children().length + 1);
            var html = `<div id="${newId}" class="row kt-margin-b-20">
                            <div class="input-group col-lg-5">
                                <input type="text" name="key" class="form-control" />
                            </div>
                            <div class="input-group col-lg-5">
                                <input type="text" name="value" class="form-control" />
                            </div>
                            <div onclick="$('#${newId}').remove();" class="col-lg-2">
                                <a href="javascript:;" class="btn btn-danger btn-icon">
                                    <i class="la la-close"></i>
                                </a>
                            </div>
                        </div>`;
            headers.append(html);

        },

        // Get the webhook headers
        getWebhookHeaders() {
            // Add the value of webhook
            var webhookHeaders = [];
            var headers = $("#webhookHeaders").children('div');
            for (var header of headers) {
                // Get key and value
                var inputs = $("#" + header.id).find('input');
                var content = {};
                for (var input of inputs) {
                    if (input.name === "key" && input.value !== "") {
                        content.key = input.value;
                    }
                    if (input.name === "value" && input.value !== "") {
                        content.value = input.value;
                    }
                }
                // Verify if header is correct before adding
                if (content.hasOwnProperty('key') && content.hasOwnProperty('value')) {
                    webhookHeaders.push(content);
                }
            }
            return webhookHeaders;
        },

        onClickAddEventTpe() {
            $("#pushButtonAlertEventTypeModal").modal("show");
        },

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(newPushButtonAlert)::onCancelButton() - called");
            this.$router.push({
                name: "pushButtonAlerts",
                params: { fromAction: "cancelButton" }
            });
        },

        // Function called when user click on the "Create notification" button
        onCreateButton() {
            console.log("Component(newPushButtonAlert)::onCreateButton() - called");
            // Prepare final data
            const data = {
                siteId: this.siteId,
                name: this.vName,
                triggerCondition: this.vCondition,
                pushEventTypeId: this.vEventType,
                severity: this.vSeverity,
                isActive: this.statusActive,
                createAnother: this.createAnother
            };
            // Built the data body items
            if (this.vDateTime == "always") {
                data.startTime = null;
                data.endTime = null;
            } else {
                if (this.vStartTime) {
                    data.startTime = this.vStartTime.toISOString();
                }
                if (this.vEndTime) {
                    data.endTime = this.vEndTime.toISOString();
                }
            }
            // Check webhook is selected and get its id
            var webhook = _.find(this.vNotificationTypesList, {name:"WEBHOOK"});
            var notificationTypes = [];
            var self = this;
            this.vNotificationTypes.forEach(notificationType => {
                if (notificationType === webhook.id) {
                    notificationTypes.push({
                        id: notificationType,
                        values: {
                            url: self.vWebhookUrl,
                            headers: self.getWebhookHeaders()
                        }
                    });
                } else {
                    notificationTypes.push({ id: notificationType });
                }
            });
            data.pushButtonNotificationTypes = notificationTypes;

            this.emptyUnselectedTrigger();
            data.isTriggerNone = (this.vTrigger === "none" ? true : false);
            data.assets = this.vAssets;
            data.assetCategories = this.vAssetCategories;
            data.assetSubcategories = this.vAssetSubcategories;

            data.isAllUsers = (this.vWhoToAlert === "all" ? true : false);
            data.roles = (this.vWhoToAlert === "roles" ? this.vRoles : []);
            data.users = (this.vWhoToAlert === "users" ? this.vUsers : []);

            this.createPushButtonAlert(data);
        },

        isEmptyForm() {
            // Return true if all control form are empty
            let isEmptyForm = true;
            if (
                this.vName &&
                this.vCondition &&
                this.vEventType &&
                this.vNotificationTypes
            ) {
                isEmptyForm = false;
            }
            return isEmptyForm;
        },

        isIQMServerDisabled() {
            if (this.currentSite && this.currentSite.IQMConfiguration && this.currentSite.IQMConfiguration.url) {
                return false;
            }
            return true;
        },

        // Are there any selected assets not declared on IQM when IQM notification is checked?
        isIQMNotDeclaredAssets() {
            // Asset checked with some undeclared assets
            if (this.vTrigger == "asset" && this.iqmNotDeclaredAssetNames && this.iqmNotDeclaredAssetNames.length > 0) {
                // Verify if the IQM Alarm server is checked
                if (this.iqmNotificationTypeId) {
                    const isIqmNotificationChecked = this.vNotificationTypes.find(notifTypeId => notifTypeId === this.iqmNotificationTypeId);
                    if (isIqmNotificationChecked) {
                        return true;
                    }
                }
            }
            return false;
        },

        // Set list of selected asset names not declared in IQM
        setIQMNotDeclaredAssetNames() {
            var self = this;
            self.iqmNotDeclaredAssetNames = null;
            if (self.siteAssetsWithTag && self.vAssets && self.vAssets.length > 0) {
                const iqmNotDeclaredAssets = _.filter(self.siteAssetsWithTag, siteAsset => {
                    const assetIdFound = _.find(self.vAssets, assetId => assetId === siteAsset.id );
                    if (assetIdFound && siteAsset.IQMStatus !== "DECLARED") {
                        return true;
                    }
                });
                self.iqmNotDeclaredAssetNames = _.map(iqmNotDeclaredAssets, "name");
            }
        },

        // Display the warning modal to inform on non declared assets on IQM
        onIQMAssetsWarningModal() {
            console.log("Component(newPushButtonAlert)::onIQMAssetsWarningModal() - called");
            const strAssets = (this.iqmNotDeclaredAssetNames ? this.iqmNotDeclaredAssetNames.join(", ") : "");
            this.iqmAssetWarning = i18n.t("geo_notif_IQMWarningAssetsNotDeclared", { assets: strAssets });
            $("#IQMAssetWarningModal").modal("show");
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle,
        "app-iqmassetwarningmodal": IQMAssetWarningModal
    },
    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        commonVueHelper.displayConfirmModalOnLeavePage(
            this,
            "newPushButtonAlert",
            "create",
            !this.isEmptyForm(),
            to,
            from,
            next
        );
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.period {
    margin-left: 5px;
}
.webhookBloc {
    margin-left:40px;
    margin-top: 20px;
    margin-bottom: -20px;
}
#header1 a:first-child {
    background: #f9f9fc;
    border: none;
    cursor: initial;
    pointer-events: none;
}
#header1 a i:first-child {
    color: #f9f9fc;
}
</style>
